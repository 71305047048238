import { useRouter } from "next/router"
import styles from "./styles.module.scss"
import { GiPresent } from "react-icons/gi"
import { HiOutlineDocumentText } from "react-icons/hi2"
import { useTranslation } from "next-i18next"
import { FaPlus } from "react-icons/fa"
import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useSubCore } from "@/hooks/useSubCore"
import redirectTo from "@/bet-nextv1/utils/redirectTo"

export const EventHome: React.FC<any> = ({ events }) => 
{
    const router                = useRouter()
    const { t }                 = useTranslation()
    const { user }              = useContext(AuthContext)
    const { setOpenLoginModal } = useSubCore()

    return (
        <div className={styles.eventHome}>
            <h1>
                <GiPresent />
                {t("Evento")} <span>EVENT</span>
                <span className={styles.more} onClick={() => 
                {
                    redirectTo("/event", user, router, setOpenLoginModal) 
                }}>
                    <FaPlus /> More
                </span>
            </h1>

            <div className={styles.listNotices}>
                {events?.map((event: any, key: any) => (
                    <div
                        className={styles.notice}
                        key={key}
                        onClick={() => redirectTo(`/event?selectedId=${event.id}`, user, router, setOpenLoginModal)}
                    >
                        <p>
                            <HiOutlineDocumentText />
                            <span>EVENT</span>
                            {event?.title}
                        </p>
                        <span>more</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
