import styles from "./styles.module.scss";
import { Options } from "@splidejs/splide";
import { ImageSet } from "../../component-nextv3/ImageSet";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Desktop, Mobile } from "../../../core-nextv3/util/useResponsive";

export const BannerSlider = ({ images }: any) => 
{
    const mainOptions: Options = {
        role       : "banner",
        type       : "loop",
        rewind     : true,
        perPage    : 1,
        perMove    : 1,
        pagination : false,
        slideFocus : true,
        start      : 0,
        arrows     : true,
        autoplay   : true,
        interval   : 3000,
    };

    return (
        <div className={styles.bannerSlider}>
            <div className={styles.content}>
                <Splide
                    options={mainOptions}
                    className={styles.slider}
                >
                    {images?.map((item: any, index: any) => (
                        <div key={index} className="splide__slide">
                            <Desktop>
                                <a href={item?.url} aria-label="Banner">
                                    {/* <ImageSet
                                    aspectRatio={ 1920/813 }
                                    image={item?.desktop} 
                                    width={2000} 
                                    priority={true}
                                    //   domainFrom={THEME_SETTING.domainFrom} 
                                    //   domainTo={THEME_SETTING.domainTo}
                                /> */}
                                    <img alt='' src={item?.desktop?._url} />
                                </a>
                            </Desktop>
                            <Mobile>
                                <a href={item?.url} aria-label="Banner">
                                    {/* <ImageSet
                                    aspectRatio={1000/1500} 
                                    image={item?.mobileImage} 
                                    width={1024} 
                                    priority={true}
                                    // domainFrom={THEME_SETTING.domainFrom} 
                                    // domainTo={THEME_SETTING.domainTo}
                                />  */}
                                    <img alt='' src={item?.mobileImage?._url} />
                                </a>
                            </Mobile>
                        </div>
                    ))}
                </Splide>
            </div>
        </div>
    );
};
